import { ConfirmAccountDataResponse } from './types/account';
import { ProgramRequest } from './types/offer-calculation';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@fundo/environments/environment';
import { Observable, of } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';

import { OfferCalculationResponse } from '../models/offer-calculation-response';
import { LeadService } from './lead.service';

@Injectable({
  providedIn: 'root',
})
export class OfferCalculationService {
  private readonly API_BASE = `${environment.apiBaseUrl}/offercalculation`;

  constructor(
    private httpClient: HttpClient,
    private leadService: LeadService,
  ) {}

  public getOfferCalculation(): Observable<OfferCalculationResponse> {
    return this.httpClient.get<OfferCalculationResponse>(`${this.API_BASE}`);
  }

  public sendProgramsData(data: ProgramRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.API_BASE}/chooseoffer`, data);
  }

  /**
   * Get customer accounts registered
   * @returns
   */
  public getConfirmationAccountData(params: HttpParams): Observable<ConfirmAccountDataResponse> {
    return this.httpClient
      .get<ConfirmAccountDataResponse>(`${this.API_BASE}/confirmAccount`, { params })
      .pipe(take(1));
  }

  /**
   * Get customer accounts registered
   * @returns
   */
  public confirmAccount(accountId: string): Observable<any> {
    return this.httpClient
      .post<any>(`${this.API_BASE}/confirmAccount`, {
        accountId,
      })
      .pipe(
        mergeMap((response: any) => {
          // This response would include status in future
          if (response.result) {
            return this.leadService.getStatus();
          }
          return of(response);
        }),
      );
  }

  /**
   * Get customer accounts registered
   * @returns
   */
  public verifyAccount(): Observable<any> {
    return this.httpClient.post<any>(`${this.API_BASE}/verifyAccount`, null);
  }
}
