import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogInfoComponent } from '@fundo/app/@ui/dialogs/dialog-info/dialog-info.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(public dialog: MatDialog) {}

  public openGeneralErrorDialog(): void {
    this.dialog.open(
      DialogInfoComponent,
      { data: { type: 'general-error'} }
    );
  }

  public showPutErrorNotification(): void {
    this.dialog.open(
      DialogInfoComponent,
      { data: { type: 'general-error'} }
    );
  }
}
